<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        :aria-label="i18n.t('Change language')"
        append-icon="expand_more"
        prepend-icon="language"
        variant="text"
      >
        {{ selectedLocale }}
      </v-btn>
    </template>

    <v-list
      v-model:selected="selectedLanguage"
      density="compact"
      elevation="0"
      border
    >
      <v-list-item
        v-for="(lang, index) in store.state.languages"
        @click="switchLocale(lang.value)"
        :key="index"
        :value="lang"
        color="primary"
      >
        <v-list-item-title
          :id="'i18n_link_' + lang.value"
          :aria-label="getAriaLabel(lang.text)"
          tracked
        >
          {{ $t(lang.text) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import baseLocales from '@/shared/assets/locale.json';
import assets from '@/shared/services/assets';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useLocale } from 'vuetify';

const i18n = useI18n();
const store = useStore();
const vuetify = useLocale();
const fetchedLocales = ref(['en']);

const selectedLanguage = computed(() => {
  const value = i18n.locale.value.split('-')[0];
  const lang = store.state.languages.find((language) => language.value === value);
  return [lang];
});

const selectedLocale = computed(() => {
  const value = i18n.locale.value.split('-')[0];
  const lang = assets.assets.locales.find((locale) => locale.value === value);
  if (!fetchedLocales.value.includes(value)) {
    void setCustomTranslations(value);
  }

  return lang.title || lang.text;
});

function getAriaLabel(text) {
  return `${i18n.t('Change language')}, ${i18n.t(text)}`;
}

function replaceLiquid(messagesWithLiquid) {
  const messages = { ...messagesWithLiquid };
  Object.keys(messages).forEach((key) => {
    const replacedKey = key.replace(/{{/g, '-%').replace(/}}/g, '%-');
    const replacedValue = messages[key].replace(/{{/g, '-%').replace(/}}/g, '%-');
    messages[replacedKey] = replacedValue;
  });
  return messages;
}

async function switchLocale(lang) {
  if (store.state.profile) {
    const apiScope = store.state.role === 'manager' ? 'manager' : 'member';
    const resp = await Api[apiScope].profile.update({ default_locale: lang });
    if (resp) store.commit('setProfile', resp.data);
  }

  await setCustomTranslations(lang);
}

async function setCustomTranslations(locale) {
  if (locale !== 'en' && !fetchedLocales.value.includes(locale)) {
    const translationKeyValue = await Api.translations.index(locale);
    const messagesWithLiquidReplacement = replaceLiquid(translationKeyValue);
    fetchedLocales.value.push(locale);
    const baseMessagesWithLiquidReplacement = replaceLiquid(baseLocales[locale]);
    i18n.setLocaleMessage(locale, {
      ...baseMessagesWithLiquidReplacement,
      ...messagesWithLiquidReplacement,
    });
  }
  i18n.locale.value = locale;
  // Vuetify uses a difference locale code for traditional Chinese, so we
  // have to translate the locale code to the correct locale code. It's like
  // Inception. :exploding_head:
  if (locale === 'zhcht') vuetify.current.value = 'zhHant';
  else vuetify.current.value = locale;
}
</script>
